import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Background from '../../../../images/hero-background.svg'

export const Container = styled.header`
  ${({ theme }) => css`
    background-image: url(${Background});
    background-position: 60% -1050px;
    margin: -160px 0px 0;
    padding: 160px 0 100px;

    ${theme.queries.medium} {
      background-position: 70% -1040px;
      margin-top: -160px;
      padding: 200px 0;
    }
  `}
`
export const Content = styled.div`
  ${({ theme }) => css`
    ${theme.queries.medium} {
      max-width: 50%;
    }
  `}
`
