/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import { setCurrentCampaignRequest } from '../../../../store/modules/campaign/actions'
import { queryCampaigns } from '../../../../services/campaigns'

import Wrapper from '../../../Wrapper'
import Spacing from '../../../Spacing'
import Title from '../../../Title'
import Text from '../../../Text'
import SearchInput, { SearchContainer } from '../../../SearchInput'
import CampaignInfo from '../../../CampaignInfo'
import Card from '../../../Card'

const ListContainer = styled.div`
  ${({ theme }) => css`
    ${theme.queries.medium} {
      display: grid;
      grid-column-gap: 30px;
      grid-row-gap: 60px;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  `}
`

const Listing = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const campaigns = useSelector(state => state.campaign.list)

  const [searchList, setSearchList] = useState([])

  const handleChange = async ({ target }) => {
    try {
      const { value } = target

      if (value.length === 0) {
        setSearchList([])
        return
      }

      const response = await queryCampaigns(value)
      setSearchList(response.data.results)
    } catch (error) {
      setSearchList([{}])
    }
  }

  const handleSelectCampaign = id => {
    dispatch(setCurrentCampaignRequest(id))
    navigate(`/campanhas/doar/${id}/`)
  }

  return (
    <Wrapper>
      <header
        css={theme => css`
          ${theme.queries.medium} {
            margin-bottom: 60px;
          }
        `}
      >
        <Title
          as="h2"
          align="center"
          mobileHeight="35"
          mobileSize="28"
          mobileAlign="center"
        >
          <FormattedMessage id="pages.campaigns.list_title" />
        </Title>
        <Text
          align="center"
          mobileSize="14"
          mobileHeight="22"
          css={theme => css`
            ${theme.queries.small} {
              margin: 20px auto 40px;
              width: 186px;
            }
          `}
        >
          <FormattedMessage id="pages.campaigns.active_campaigns" />
        </Text>
      </header>

      <SearchContainer>
        <SearchInput
          placeholder={intl.formatMessage({
            id: 'pages.campaigns.search',
          })}
          minLength={2}
          debounceTimeout={100}
          onChange={handleChange}
        />
        <ul>
          {searchList.map(item => {
            if (!item.id) {
              return (
                <li>
                  <Link to="/" onClick={e => e.preventDefault()}>
                    <em>
                      <FormattedMessage id="no_result_found" />
                    </em>
                  </Link>
                </li>
              )
            }
            return (
              <li key={item.id}>
                <Link to={`/campanhas/doar/${item.id}/`}>
                  <strong>{item.campaignName}</strong> - {item.institutionName}
                </Link>
              </li>
            )
          })}
        </ul>
      </SearchContainer>

      <Spacing top="80" bottom="252" topMobile="40" bottomMobile="40">
        <ListContainer>
          {campaigns
            .filter(campaign => !campaign.campaignNestle)
            .map(campaign => (
              <Card
                key={campaign.id}
                className="listing"
                clickFunction={() => handleSelectCampaign(campaign.id)}
                animate
                upImagesrc={
                  campaign.imagePath ||
                  'https://via.placeholder.com/728x400.png?text=Sem%20Imagem'
                }
              >
                <CampaignInfo
                  clickFunction={() => handleSelectCampaign(campaign.id)}
                  campaignName={campaign.campaignName}
                  daysUntilEnd={campaign.daysUntilEnd}
                  distance={campaign.distance}
                  goalValue={campaign.goalValue}
                  id={campaign.id}
                  institutionName={campaign.institutionName}
                  totalDonatedPercentage={campaign.totalDonatedPercentage}
                  totalApprovedDonatedValue={campaign.totalApprovedDonatedValue}
                />
              </Card>
            ))}
        </ListContainer>
      </Spacing>
    </Wrapper>
  )
}

export default Listing
