/** @jsx jsx */
import { jsx } from '@emotion/core'

import Layout from '../components/Layout'

import Header from '../components/pages/Campanhas/Header'
import Listing from '../components/pages/Campanhas/Listing'

const IndexPage = () => (
  <Layout>
    <Header />
    <Listing />
  </Layout>
)

export default IndexPage
