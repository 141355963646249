/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import Button from '../Button'
import Text from '../Text'

import locationIcon from '../../images/ic-location.svg'
import ProgressBar from './ProgressBar'

const Container = styled.div`
  border: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 30px;
  position: relative;
`

const Actions = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.celestialblue};
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    div {
      display: flex;

      img {
        margin-right: 10px;
      }
    }

    ${theme.queries.small} {
      div.distance {
        min-width: 90px;
      }
    }
  `}
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    font-family: 'Above', sans-serif;
    margin-bottom: 10px;
  `}
`

const Location = ({ distance }) => (
  <Text size="14" noMargin>
    {distance} km
  </Text>
)

const CampaignInfo = ({
  campaignName,
  daysUntilEnd,
  distance,
  goalValue,
  institutionName,
  totalDonatedPercentage,
  totalApprovedDonatedValue,
  clickFunction,
}) => {
  const intl = useIntl()

  return (
    <Container onClick={clickFunction}>
      <div>
        <StyledText
          as="h4"
          size="21"
          weight="500"
          lineHeight="30"
          mobileSize="16"
          noMargin
          transform="capitalize"
          title={campaignName}
        >
          {campaignName}
        </StyledText>
        <Text
          size="14"
          color="celestialblue"
          weight="300"
          noMargin
          marginBottomMobile="5"
        >
          {intl.formatMessage({ id: 'ends_in' })} {daysUntilEnd}{' '}
          {intl.formatMessage({ id: 'days' })}
        </Text>
      </div>
      <StyledText size="16" color="gray" weight="400" mobileSize="14" noMargin>
        {institutionName}
      </StyledText>
      <Actions>
        <div className="distance">
          <img src={locationIcon} alt="Ícone de distâncias" />
          <Location
            distance={distance > 50 ? Math.floor(distance) : distance}
          />
        </div>
        <Button
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          uppercase
        >
          <FormattedMessage id="donate_now" />
        </Button>
        <ProgressBar
          percentage={totalDonatedPercentage}
          target={goalValue}
          accumulated={totalApprovedDonatedValue}
        />
      </Actions>
    </Container>
  )
}

Location.propTypes = {
  distance: PropTypes.number.isRequired,
}

CampaignInfo.propTypes = {
  campaignName: PropTypes.string.isRequired,
  clickFunction: PropTypes.func,
  daysUntilEnd: PropTypes.number,
  distance: PropTypes.number.isRequired,
  goalValue: PropTypes.number,
  institutionName: PropTypes.string.isRequired,
  totalDonatedPercentage: PropTypes.number,
  totalApprovedDonatedValue: PropTypes.number,
}

CampaignInfo.defaultProps = {
  clickFunction: () => {},
  daysUntilEnd: 0,
  goalValue: 0,
  totalDonatedPercentage: 0,
  totalApprovedDonatedValue: 0,
}

export default CampaignInfo
