/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Text from '../../Text'

const Container = styled.div`
  bottom: 0;
  height: 42px;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledBar = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightgray};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 12px;
    width: 100%;
  `}
`

const Bar = ({ percentage = 0 }) => (
  <StyledBar>
    <div
      css={theme => css`
        background: ${theme.colors.green};
        width: ${percentage}%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      `}
    />
  </StyledBar>
)

const ProgressBar = ({ target, accumulated, percentage }) => (
  <Container>
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
      `}
    >
      <Text size="14" weight="500" color="green" italic>
        {percentage}%
      </Text>
      <div>
        <Text size="14" italic color="green">
          R${accumulated}
        </Text>{' '}
        <Text size="14" color="gray" italic>
          {' '}
          / R${target}
        </Text>
      </div>
    </div>
    <Bar percentage={percentage} />
  </Container>
)

Bar.propTypes = {
  percentage: PropTypes.number.isRequired,
}

ProgressBar.propTypes = {
  target: PropTypes.number.isRequired,
  accumulated: PropTypes.number,
  percentage: PropTypes.number.isRequired,
}

ProgressBar.defaultProps = {
  accumulated: 0,
}

export default ProgressBar
